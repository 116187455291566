import {
  login,
  currentUser,
  codeLogin
} from '@/api/user'
import {
  setToken,
  removeToken
} from '@/utils/auth'
import {
  getToken
} from '@/utils/auth'
import {
  Message
} from 'element-ui'
const getDefaultState = () => {
  return {
    user: {},
    token: '',
    loginName: '',
    loginMode: '',
    logo: 1,
    routeList: []
  }
}

const state = getDefaultState()

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_LOGINMODE: (state, loginMode) => {
    state.loginMode = loginMode
  },
  SET_LOGO: (state, logo) => {
    state.logo = logo
  },
  SET_LOGINNAME: (state, loginName) => {
    state.loginName = loginName
  },
  SET_ROUTE_LIST: (state, routeList) => {
    state.routeList.push(routeList)
  },
  DELETED_ROUTE_LIST: (state, routeList) => {
    state.routeList.pop()
    state.routeList.pop()
  },
  SET_USER: (state, user) => {
    state.user = user
    // console.log('state.user', state.user)
    // state.name = user.username
    // state.id = user.id
    // state.accountType = user.accountType // 用户类型 0 系统管理员 1：学校管理员 2：教师 3：学生
    // state.avatar = user.avatar
  },
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  //账号密码登录
  login({
    commit
  }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo).then(response => {
        const {
          data
        } = response
        if (data.tokenValue) {
          commit('SET_TOKEN', data.tokenValue)
          setToken(data)
        } else {
          commit('SET_LOGINNAME', data.loginName)
          commit('SET_LOGINMODE', data.loginMode)
        }
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  //验证码登录
  codeLogin({
    commit
  }, userInfo) {
    console.log('userInfo', userInfo)
    return new Promise((resolve, reject) => {
      codeLogin(userInfo).then(response => {
        const {
          data
        } = response
        commit('SET_TOKEN', data.tokenValue)
        setToken(data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  //获取用户信息
  getUserInfo({
    commit
  }) {
    return new Promise((resolve, reject) => {
      currentUser().then(async response => {
        const {
          data
        } = response
        if (!data) {
          Message.warning('获取用户数据失败,请重新登录')
          return
        }
        commit('SET_TOKEN', getToken())
        commit('SET_USER', data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 清除本地token
  removeToken({
    commit
  }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      sessionStorage.removeItem('activeSystem')
      commit('RESET_STATE')
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}