import Vue from "vue";
import Router from "vue-router";
import store from "@/store/index";
import {
  getToken
} from "@/utils/auth";
import VueLayout from "@/components/VueLayout.vue";
import examinationView from "@/views/examinationView/examinationView.vue";
import {
  Message
} from "element-ui";
Vue.use(Router);

export const constantRoutes = [{
    path: "/login",
    component: () => import("@/views/login/index"),
    name: "登录",
    hidden: true,
    meta: {
      needBottom: "1",
    },
  },
  {
    path: "/register",
    component: () => import("@/views/login/Register"),
    name: "注册",
    hidden: true,
    meta: {
      needBottom: "1",
    },
  },
  {
    path: "/home",
    component: () => import("@/views/home/Home"),
    name: "首页",
  },
  {
    path: "/home",
    component: () => import("@/views/home/Home"),
    name: "首页",
  },
  {
    path: "/recruitmentPositions",
    component: () => import("@/views/post/recruitmentPositions"),
    name: "招聘",
  },
  {
    path: "/internshipTraining",
    component: () => import("@/views/internship/internshipTraining.vue"),
    name: "实习实训",
  },
  {
    path: "/companyInfo",
    component: () => import("@/views/company/companyInfo"),
    name: "完善资料",
  },
  {
    path: "/message",
    component: () => import("@/views/messageView/messageView"),
    name: "消息",
  },
  {
    path: "/doExam",
    component: () => import("@/views/student/exam/doExam"),
    name: "考试",
  },
  {
    path: "/examina",
    component: examinationView,
    name: "学习中心",
    redirect: "/examina/MyCourse",
    children: [{
        path: "MyCourse",
        component: () => import("@/views/examinationView/MyCourse"),
        name: "我的课程",
        // meta: {
        //   requiresLogin: true
        // }
      },
      {
        path: "MyExercise",
        component: () => import("@/views/examinationView/MyExercise"),
        name: "我的练习",
      },
      {
        path: "MyExam",
        component: () => import("@/views/examinationView/MyExam"),
        name: "我的考试",
      },
      {
        path: "LearningStatistics",
        component: () => import("@/views/examinationView/LearningStatistics"),
        name: "学习统计",
      },
      {
        path: "myCertificate",
        component: () => import("@/views/examinationView/myCertificate"),
        name: "课程证书",
      },
      {
        path: "MyOrder",
        component: () => import("@/views/examinationView/MyOrder"),
        name: "我的订单",
      },
      {
        path: "Jobcompetency",
        component: () => import("@/views/examinationView/JobcompetencyView"),
        name: "岗位能力",
      },
      {
        path: "personalResume",
        component: () => import("@/views/examinationView/personalResume"),
        name: "个人简历",
      },
      {
        path: "jobRecords",
        component: () => import("@/views/examinationView/jobRecords"),
        name: "求职记录",
      },
      {
        path: "communicate",
        component: () => import("@/views/examinationView/communicateView"),
        name: "沟通",
      },
    ],
  },
  {
    path: "/studyOnline",
    component: () => import("@/views/study/File.vue"),
    name: "视频组件",
    meta: {
      needBottom: "1",
    },
  },
  {
    path: "/personalCenter",
    component: () => import("@/views/pensonalCenter/homePage.vue"),
    name: "个人中心",
    redirect: "/personalCenter/personalData",
    children: [{
        path: "personalData",
        component: () => import("@/views/pensonalCenter/personalData"),
        name: "个人资料",
      },
      {
        path: "accountSecurity",
        component: () => import("@/views/pensonalCenter/accountSecurity"),
        name: "账号安全",
      },
      {
        path: "cancelAccount",
        component: () => import("@/views/pensonalCenter/cancelAccount"),
        name: "注销账号",
      },
    ],
  },
  {
    path: "/messageNotification",
    component: () => import("@/views/MsgNotification/MsgPage.vue"),
    name: "个人中心",
    redirect: "/messageNotification/QuestionsAndAnswers",
    children: [{
        path: "QuestionsAndAnswers",
        component: () => import("@/views/MsgNotification/QuestionsAndAnswers"),
        name: "问答通知",
      },
      {
        path: "MiscussionGroup",
        component: () => import("@/views/MsgNotification/MiscussionGroup"),
        name: "讨论组",
      },
      {
        path: "ExamNotice",
        component: () => import("@/views/MsgNotification/ExamNotice"),
        name: "考试通知",
      },
      {
        path: "CorrectionNotice",
        component: () => import("@/views/MsgNotification/CorrectionNotice"),
        name: "批改通知",
      },
      {
        path: "OrderNotification",
        component: () => import("@/views/MsgNotification/OrderNotification"),
        name: "订单通知",
      },
    ],
  },
  {
    path: "/student",
    component: VueLayout,
    redirect: "/student/courseList",
    name: "课程中心",
    meta: {
      requiresLogin: true,
    },
    children: [{
        path: "courseList",
        component: () => import("@/views/student/course/courseList"),
        name: "课程中心",
        meta: {
          // requiresLogin: true
        },
      },
      {
        path: "courseDetails",
        component: () => import("@/views/student/course/courseDetails"),
        name: "课程详情",
      },
      {
        path: "coursePractice",
        component: () => import("@/views/student/course/coursePractice"),
        name: "课程练习",
      },
      {
        path: "studyCourse",
        component: () => import("@/views/student/course/studyCourse"),
        name: "课程学习",
      },
    ],
  },
  {
    path: "/myCenter",
    component: () => import("@/views/student/myCenter/myCenter.vue"),
    name: "个人中心",
  },
  {
    path: "/orderTopic",
    component: () => import("@/views/student/practice/orderTopic.vue"),
    name: "顺序练习",
  },
  {
    path: "/testExam",
    component: () => import("@/views/student/practice/testExam.vue"),
    name: "模拟考试",
  },
  {
    path: "/chapterPractice",
    component: () => import("@/views/student/practice/chapterPractice.vue"),
    name: "章节练习",
  },
  {
    path: "/typePractice",
    component: () => import("@/views/student/practice/typePractice.vue"),
    name: "题型练习",
  },
  {
    path: "/favoriteTopic",
    component: () => import("@/views/student/practice/favoriteTopic.vue"),
    name: "我的收藏",
  },
  {
    path: "/favoriteWrongDoQuestion",
    component: () => import("@/views/student/practice/favoriteWrongDoQuestion.vue"),
    name: "选题练习",
  },
  {
    path: "/practiceRecord",
    component: () => import("@/views/student/practice/practiceRecord.vue"),
    name: "做题记录",
  },
  {
    path: "/practiceRecordDetails",
    component: () =>
      import("@/views/student/practice/practiceRecordDetails.vue"),
    name: "做题记录详情",
  },
  {
    path: "/myWrongQuestion",
    component: () => import("@/views/student/practice/myWrongQuestion.vue"),
    name: "我的错题",
  },
  {
    path: "/",
    redirect: "/home",
    hidden: false,
  },
  {
    path: "/post",
    component: () => import("@/views/post/postInfo.vue"),
    name: "职位招聘",
  },
  {
    path: "/post/postDetail/:id",
    component: () => import("@/views/post/postDetail.vue"),
    name: "岗位详情",
  },
  {
    path: "/post/searchPost",
    component: () => import("@/views/post/searchPost.vue"),
    name: "搜索职位",
  },
  {
    path: "/company/searchCompany",
    component: () => import("@/views/post/searchCompany.vue"),
    name: "搜索公司",
  },
  {
    path: "/company/companyInfo",
    component: () => import("@/views/post/enterpriseHomePage.vue"),
    name: "公司详情",
  },
];
export const RoutesStu = [{}];
const createRouter = () =>
  new Router({
    routes: constantRoutes,
  });

const router = createRouter();
// const hasToken = getToken()

router.beforeEach((to, from, next) => {
  const noNeedToken = ["/register", "/login", "/home"];
  //没有token的情况
  if (!getToken()) {
    store.commit("user/SET_LOGO", 1);
    if (noNeedToken.includes(to.path) || !to.meta.requiresLogin) {
      next();
    }
    if (to.meta.requiresLogin) {
      Message.warning("需要登录才能访问该页面");
      next({
        path: "login",
      });
    }
  } else {
    //有token的情况
    if (!store.state.user.user.id) {
      //刷新页面vuex数据丢失
      store.dispatch("user/getUserInfo");
    }
    store.commit('user/SET_ROUTE_LIST', to.fullPath)
    next();
  }
});

export default router;